<template>
  <div class="manager-work-category-page">
    <div class="add-wrapper">
      <el-button size="mini" class="green-btn icon-btn" @click="handleAddCategory">
        <i class="el-icon-plus"></i> 新增类型
      </el-button>
    </div>

    <div class="div-scroll">
      <div class="category-wrapper">
        <ul class="category-list">
          <!-- <li
          v-for="item in categoryList"
          :key="item.id"
          :style="{ 'background-image': 'url(' + item.image + ')' }"
        > -->
          <li v-for="(item, index) in categoryList" :key="item.id">
            <i class="el-icon-error icon-close" @click="handleUpdateDeleteStatus(item, index)"></i>
            <div class="bg-mask"></div>
            <span class="title ellipsis">{{ item.name }}</span>

            <div class="checked-mask">
              <i class="iconfont icon-duigou"></i>
            </div>
            <div class="unchecked-mask"></div>
          </li>
        </ul>
      </div>

      <div class="title-wrapper">社区不显示的领域</div>

      <div class="category-wrapper">
        <ul class="category-list">
          <!-- <li
          v-for="item in categoryList"
          :key="item.id"
          :style="{ 'background-image': 'url(' + item.image + ')' }"
        > -->
          <li v-for="(item, index) in categoryListOfDelete" :key="item.id">
            <i class="el-icon-success icon-close" @click="handleUpdateDeleteStatus(item, index)"></i>
            <div class="bg-mask"></div>
            <span class="title ellipsis">{{ item.name }}</span>

            <div class="checked-mask">
              <i class="iconfont icon-duigou"></i>
            </div>
            <div class="unchecked-mask"></div>
          </li>
        </ul>
      </div>

    </div>



    <el-dialog :visible.sync="dialogFormShow" :close-on-click-modal="false" custom-class="form-dialog"
      :before-close="handleFormClose" title="新增" width="35%">
      <el-form :model="form" :rules="rules" ref="xForm" class="x-form" label-width="120px">
        <el-form-item prop="name" label="类型名称">
          <el-input v-model="form.name" placeholder="请填写类型名称"></el-input>
        </el-form-item>
        <!-- <el-form-item prop="image" label="上传类型图片">
          <el-upload
            action=""
            accept="image/jpeg,image/jpg,image/png,image/bmp"
            :limit="1"
            list-type="picture-card"
            :before-upload="beforeUpload"
            :http-request="customUpload"
            :on-remove="handleRemove"
            :on-exceed="handleExceed"
            ref="imageUpload"
          >
            <div class="upload-btn-wrapper">
              <i class="el-icon-plus"></i>
              <div class="el-upload__text">将作品拖到此处上传</div>
            </div>
            <i class="el-icon-plus" slot="default"></i>
            <div slot="tip" class="el-upload__tip">上传图片大小不超过50Kb</div>
          </el-upload>
        </el-form-item> -->
        <el-form-item class="btn-group">
          <el-button class="s-btn" @click="onSubmit" :loading="isLoading">提交</el-button>
          <el-button class="c-btn" @click="onCancel" :loading="isLoading">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";

export default {
  data() {
    return {
      SUCCESS_CODE: 0,
      categoryList: [],
      categoryListOfDelete: [],
      dialogFormShow: false,
      form: {
        name: "",
        image: "",
      },
      rules: {
        name: [
          { required: true, message: "请填写类型名称", trigger: "blur" },
          { min: 2, max: 6, message: "长度在 2 到 6 个字符", trigger: "blur" },
        ],
        image: [{ required: true, message: "请上传类型图片", trigger: "blur" }],
      },
      isLoading: false,
    };
  },
  created() {
    this.getCategoryList(0);
    this.getCategoryList(1);
  },
  methods: {
    async getCategoryList(isDelete) {
      const response = await this.$http.get(`/dict-category/list?isDelete=${isDelete}`)

      if (response.code == this.SUCCESS_CODE) {
        const listKey = ["categoryList", "categoryListOfDelete"][isDelete]
        this[listKey] = response.data;
      }
    },

    handleAddCategory() {
      this.dialogFormShow = true;
    },

    addCategory() {
      this.isLoading = true;
      let params = { name: this.form.name, image: this.form.image };
      this.$http
        .post("/dict-category/save", params)
        .then((res) => {
          if (res.code == this.SUCCESS_CODE) {
            this.$message.success("操作成功！");
            this.$refs.xForm.resetFields();
            // this.$refs.imageUpload.clearFiles();
            this.dialogFormShow = false;
            this.getCategoryList(0);
          } else {
            this.$message.error("操作失败！");
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          this.$message.error("操作失败！");
        });
    },

    // 更新删除状态
    handleUpdateDeleteStatus(item, index) {
      const { message, title, type } = [
        { message: "此操作将删除该作品领域，是否继续?", title: "警告", type: "warning" },
        { message: "此操作将恢复该作品领域，是否继续?", title: "提示", type: "warning" }
      ][item.isDelete]

      this.$confirm(message, title, { type, center: true, confirmButtonText: "确定", cancelButtonText: "取消" })
        .then(() => this.requestUpdate(item, index))
    },

    async requestUpdate(item, index) {
      const { id, name, image, isDelete } = item;
      const response = await this.$http.post(
        "/dict-category/update",
        { id, name, image, isDelete: Number(!isDelete) }
      )

      if (response.code == this.SUCCESS_CODE) {
        const _item = { ...item, isDelete: Number(!item.isDelete) }
        if (_item.isDelete) {
          this.categoryList.splice(index, 1)
          this.categoryListOfDelete.unshift(_item)
        } else {
          this.categoryListOfDelete.splice(index, 1)
          this.categoryList.unshift(_item)
        }
      } else {
        this.$message.error("修改失败！")
      }
    },

    onSubmit() {
      console.log(this.form);
      this.$refs.xForm.validate((valid) => {
        if (valid) {
          this.addCategory();
        } else {
          return;
        }
      });
    },
    onCancel() {
      this.$refs.xForm.resetFields();
      // this.$refs.imageUpload.clearFiles();

      this.dialogFormShow = false;
    },
    handleFormClose(done) {
      this.$refs.xForm.resetFields();
      // this.$refs.imageUpload.clearFiles();
      done();
    },
    beforeUpload(file) {
      // console.log(file);
      if (file.size > 51200) {
        this.$message.warning("您上传得图片过大，请重新上传");
        return false;
      } else {
        return true;
      }
    },
    // 自定义上传方法
    customUpload(e) {
      console.log(e);
      let self = this;
      var file = e.file;
      // this.fileList[0] = file;
      var key = uuidv4() + ".png";
      self.obsUpload(e.file, key);
    },
    obsUpload(file, key, callback) {
      let self = this;
      // self.pageLoading = true;
      // this.uploadLoading = true;
      var ak = self.$OBS_AK;
      var sk = self.$OBS_SK;
      var server = self.$OBS_SERVER;
      var bucket = self.$OBS_BUCKET;
      let prefix = self.$OBS_PREFIX;
      var obsClient = new ObsClient({
        access_key_id: ak,
        secret_access_key: sk,
        server: server,
        timeout: 60 * 5,
      });
      // var key = uuidv4() + file.name; // 生成uuid，作为唯一名称
      // put objects
      obsClient
        .putObject({
          Bucket: bucket,
          Key: key,
          Metadata: { property: "property-value" },
          SourceFile: file,
        })
        .then(function (result) {
          if (result.CommonMsg.Status == 200) {
            // callback && callback(file, key);

            // 图片地址
            let imgUrl = prefix + key;
            // console.log(imgUrl);
            self.form.image = imgUrl;
          }
        })
        .catch(function (err) {
          // self.pageLoading = false;
          // self.uploadLoading = false;
          this.$message.error(err);
          console.error(err);
        });
    },
    // 图片上传超出一张时提醒
    handleExceed(file, fileList) {
      this.$message.warning("只能上传一张图片，请先删除后上传！");
    },
    handleRemove(file, fileList) {
      this.form.image = "";
    },
  },
};
</script>

<style>
.el-upload-list--picture-card {
  position: absolute;
}
</style>
